import { AbstractRepository } from '@/repositories/abstract-repository';
import { CrmApiElement } from '@/models/base';
import { PromiseStore } from '@/store/promise-store';
import {
    NylasAuthCodeStoreDto,
    NylasAuthTokenObject,
    NylasAuthUrl,
    NylasCalendar,
    NylasCalendarEvent,
    NylasCalendarListItem,
    NylasEventFilter,
    NylasValidateEmailResponse
} from '@/integrations/models/nylas';
import { getModule } from 'vuex-module-decorators';

const promisesStore = getModule(PromiseStore);

export class NylasRepository extends AbstractRepository<CrmApiElement> {
    protected endpoint = 'integrations/nylas';

    public async getCalendarEvents(
        start: string,
        end: string,
        extraParams: NylasEventFilter | null = null,
        force = false
    ): Promise<Array<NylasCalendarEvent>> {
        const params = {
            starts_after: start,
            ends_before: end
        };

        Object.assign(params, extraParams);

        return await promisesStore.initPromise(
            {
                hash: 'nylas-calendar-events-' + JSON.stringify(params),
                closure: async () => {
                    const response = await this.client.get<Array<NylasCalendarEvent>>(this.endpoint + '/calendars/events', { params: params });
                    return response.data;
                },
                force: force
            }
        );
    }

    public async getCalendarEventsForCenter(centerId: number): Promise<Array<NylasCalendarEvent> | never[]> {
        const response = await this.client.get<Array<NylasCalendarEvent>>(this.endpoint + '/' + centerId + '/calendars/events');
        return response.data;
    }

    public async getCalendarsForCenter(centerId: number): Promise<Array<NylasCalendar>> {
        const response = await this.client.get<Array<NylasCalendar>>(this.endpoint + '/' + centerId + '/calendars');
        return response.data;
    }

    public async getCalendarsFromAccessToken(grantId: string, accessToken: string): Promise<Array<NylasCalendarListItem>> {
        const response = await this.client.get<Array<NylasCalendarListItem>>(this.endpoint + '/calendars/access-token?token=' + accessToken + '&grant_id=' + grantId);
        return response.data;
    }

    public async getEmailAuthUrl(email: string): Promise<string> {
        const response = await this.client.get<NylasAuthUrl>(this.endpoint + '/auth-url-email?email=' + email);
        return response.data.url;
    }

    public async getTokenObject(authCode: string, scope: number): Promise<NylasAuthTokenObject> {
        const response = await this.client.get<NylasAuthTokenObject>(this.endpoint + '/auth-token?code=' + authCode + '&scope=' + scope);
        return response.data;
    }

    public async revokeEmailIntegration(): Promise<void> {
        await this.client.delete(this.endpoint + '/email');
    }

    public async revokeEmailIntegrationByUserId(userId: number): Promise<void> {
        await this.client.delete(this.endpoint + '/email/' + userId);
    }

    public async revokeCalendarIntegration(): Promise<void> {
        await this.client.delete(this.endpoint + '/calendars');
    }

    public async revokeCalendarIntegrationByCenterId(centerId: number): Promise<void> {
        await this.client.delete(`${this.endpoint}/${centerId}/calendars`);
    }

    public async storeAuthCode(codeDto: NylasAuthCodeStoreDto): Promise<void> {
        await this.client.post(this.endpoint + '/auth-store-code', codeDto);
    }

    public async validateEmailAddress(address: string, isForCalendar = true): Promise<boolean> {
        const response = await this.client.get<NylasValidateEmailResponse>(this.endpoint + '/email/validate?address=' + address + '&is_for_calendar=' + isForCalendar);
        return response.data.valid;
    }

    public async getCalendarAuthUrl(email: string): Promise<string> {
        const response = await this.client.get<NylasAuthUrl>(this.endpoint + '/auth-url-calendar?email=' + email);
        return response.data.url;
    }
}
