
























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { ClientInfoStore } from '@/organizations/corporate/stores/client-info-store';
import LocationCallSettingsModal from '@/organizations/locations/components/LocationCallSettingsModal.vue';
import { CenterCallSettingsMapper } from '@/organizations/locations/mappers/center-call-settings-mapper';
import {
    CenterCallSettingsDto,
    CenterSettingsModalPayload
} from '@/organizations/locations/models/center-call-settings';
import { NylasScope } from '@/integrations/nylas-constants';
import { Component, Mixins, Prop, Watch, Ref } from 'vue-property-decorator';
import { Center, CenterHours } from '@/organizations/locations/models/center';
import { CenterDto, CenterDtoInterface, CenterStaffDtoInterface } from '@/organizations/locations/models/center-dto';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { AuthStore } from '@/store/auth-store';
import { LoadingStore } from '@/store/loading-store';
import { TimezonesStore } from '@/core/timezones/timezones-store';
import { CenterMapper } from '@/organizations/locations/mappers/center-mapper';
import { ClassMapper } from '@/organizations/locations/mappers/class-mapper';
import { CentersRepository } from '@/organizations/locations/repositories/centers-repository';
import { EventTypes } from '@/constants/event-type-constants';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { StatsStore } from '@/store/stats-store';
import { ClassesRepository } from '@/organizations/locations/repositories/classes-repository';
import {
    ClassCreateDtoInterface,
    ClassCreatePostDto,
    ClassUpdateDtoInterface
} from '@/organizations/locations/models/class';
import store from '@/store';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { OrganizationLevel } from '@/organizations/levels/models/org-level-models';
import { Org } from '@/models/organization/org';
import { OrgLevelsStore } from '@/organizations/levels/store/org-levels-store';
import { OrgsStore } from '@/store/orgs-store';
import { LevelTypes } from '@/organizations/levels/level-constants';
import { BrandsStore } from '@/organizations/brands/store/brands-store';
import { Brand } from '@/organizations/brands/models/brand-models';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import { SocialMediaRootURLConstants } from '@/core/models/social-media-link';
import UploadLogo from '@/automation/landing-pages/components/UploadLogo.vue';
import { Logo } from '@/logo/models/logo';
import { LogoMapper } from '@/logo/mappers/logo-mapper';
import ManageHours from '@/organizations/locations/components/ManageHours.vue';
import { VForm } from '@/types/types';
import SaveButton from '@/components/base/SaveButton.vue';
import { locationPositionFields } from '@/staff/staff-position-service';
import CenterAscendingStaffList from '@/staff/components/CenterAscendingStaffList.vue';
import { InterfaceSettingsStore } from '@/dashboards/store/interface-settings-store';
import { SettingNames } from '@/dashboards/models/interface-settings-models';
import { EnrollmentCenterSettingsStore } from '@/enrollment-center/store/enrollment-center-settings-store';
import { ENROLLMENT_REP_ASSIGNMENT_FLOW_LOCATION } from '@/constants/enrollment-team-constants';
import BaseClose from '@/components/base/BaseClose.vue';
import { reformatCenterHours, reformatCommHours, nullifyBlankHours } from '@/organizations/locations/center-utils';
import NylasCalendarAuth from '@/integrations/components/NylasCalendarAuth.vue';
import NylasCalendarAddition from '@/integrations/components/NylasCalendarAddition.vue';
import { NylasRepository } from '@/integrations/repositories/nylas-repository';
import NylasCalendarSelect from '@/integrations/components/NylasCalendarSelect.vue';
import {
    NylasAuthCodeObject,
    NylasAuthTokenObject,
    NylasCalendar,
    NylasCalendarListItem
} from '@/integrations/models/nylas';
import { RegionsBusiness } from '@/locales/locales';

const authState = getModule(AuthStore, store);
const centerCallSettingsMapper = new CenterCallSettingsMapper();
const clientInfoStore = getModule(ClientInfoStore);
const loadingState = getModule(LoadingStore);
const timezonesStore = getModule(TimezonesStore);
const statsStore = getModule(StatsStore);
const centersRepo = new CentersRepository();
const centerMapper = new CenterMapper();
const classesRepo = new ClassesRepository();
const classMapper = new ClassMapper();
const orgLevelsStore = getModule(OrgLevelsStore);
const orgsState = getModule(OrgsStore);
const brandsState = getModule(BrandsStore);
const featuresStore = getModule(FeaturesStore);
const logoMapper = new LogoMapper();
const settingsStore = getModule(InterfaceSettingsStore);
const enrollmentSettingsStore = getModule(EnrollmentCenterSettingsStore);
const nylasRepo = new NylasRepository();

interface ClassComparison {
    original: ClassCreateDtoInterface | ClassUpdateDtoInterface;
    current: ClassCreateDtoInterface | ClassUpdateDtoInterface;
}

@Component({
    components: {
        NylasCalendarSelect,
        BaseClose,
        CenterAscendingStaffList,
        LocationCallSettingsModal,
        ManageHours,
        UploadLogo,
        SaveButton,
        NylasCalendarAuth,
        NylasCalendarAddition
    }
})
export default class ManageLocation extends Mixins(LocaleMixin, BasicValidationMixin) {
    // v-model whether we should show it
    @Prop({ default: false }) readonly value!: boolean;
    @Prop({ required: true }) readonly location!: Center | null;
    @Ref('form') readonly form!: VForm;

    private loadingKey = 'manageLocation';
    private centerCallSettingsDto: CenterCallSettingsDto | null = null;
    private communicationsHoursRemapped: CenterHours | null = null;
    private centerDto: CenterDtoInterface = new CenterDto();
    private logo: Logo | null = null;
    private logoExists = false;
    private loaded = false;
    private validForm = false;
    private showSnack = false;
    private snackText = '';
    private snackLocation: Center | null = null;
    private newCenterId = 0;
    private classrooms: Array<ClassComparison> = [];
    private classroomsRemoved: Array<ClassCreateDtoInterface | ClassUpdateDtoInterface> = [];
    private statuses = [
        {
            value: true,
            label: 'Active'
        },
        {
            value: false,
            label: 'Inactive'
        }
    ];

    private multipleReportingLevels = false;
    private levelsMap: Map<number, string> = new Map();
    private defaultOrgId = 1;
    private reportingLevelInfo: Array<{ text: string; value: number }> = [];
    private showLocationCallSettingsButton = true;
    private showLocationCallSettingsDialog = false;
    private showUploadDialog = false;
    private logoChange = false;
    private socialMediaLinksPanelIndex: number | undefined = -1;
    private hoursOfOperationPanelIndex: number | undefined = -1;
    private automatedCommunicationHoursPanelIndex: number | undefined = -1;
    private facebookRootConstant = SocialMediaRootURLConstants.FACEBOOK;
    private youtubeRootConstant = SocialMediaRootURLConstants.YOUTUBE;
    private linkedinRootConstant = SocialMediaRootURLConstants.LINKEDIN;
    private instagramRootConstant = SocialMediaRootURLConstants.INSTAGRAM;
    private twitterRootConstant = SocialMediaRootURLConstants.TWITTER;
    private pinterestRootConstant = SocialMediaRootURLConstants.PINTEREST;
    private yelpRootConstant = SocialMediaRootURLConstants.YELP;
    private rootParts: Array<string> = [];
    private variableTagsPanelIndex: number | undefined = -1;
    private locationStaffPanelIndex: number | undefined = -1;
    private locationStaffPositions = locationPositionFields;
    private pipelineValueSettingsShow = false;
    private updatedEvent = EventTypes.UPDATED;
    private closedEvent = EventTypes.CLOSE;
    private nextEvent = EventTypes.NEXT;
    private addedEvent = EventTypes.ADDED;
    private hoursKey = 0;
    private nylasCalendarOpen = false;
    private nylasSelectCalendarOpen = false;
    private nylasCalendarAdditionOpen = false;
    private nylasCalendar: string | null = null;
    private nylasCalendars: Array<NylasCalendarListItem> = [];
    private nylasAuth: NylasAuthCodeObject | null = null;
    private nylasTokenObject: NylasAuthTokenObject | null = null;
    private centerNylasCalendars: Array<NylasCalendar> = [];

    get brands(): Array<Brand> {
        return brandsState.stored;
    }

    get modelValue(): boolean {
        return this.value;
    }

    set modelValue(showIt: boolean) {
        // Emit, don't set the value. If you set it, you will get a direct property mutation error.
        this.$emit('input', showIt);
    }

    get isAdd() {
        return this.location === null;
    }

    get isBrandsFeatureEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.BRANDS) &&
            featuresStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    get isClassroomsFeatureEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.CLASSROOMS);
    }

    get isEnrollmentCenterEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.ENROLLMENT_CENTER) && enrollmentSettingsStore.storedSettings?.flow?.id === ENROLLMENT_REP_ASSIGNMENT_FLOW_LOCATION;
    }

    get isLocationMergeFieldsFeatureEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.LOCATION_MERGE_FIELDS);
    }

    get isSuperUser(): boolean {
        return authState.userInfoObject?.is_full_superuser ?? false;
    }

    get isOrgInfoSet(): boolean {
        // temporarily disable new phone numbers in the US
        return authState.userCountry !== 'us';
    }

    get teamName() {
        return enrollmentSettingsStore.settings ? enrollmentSettingsStore.settings.name : 'Enrollment Team';
    }

    get timezones() {
        return timezonesStore.stored;
    }

    get userId(): number {
        return authState.userInfoObject?.id ?? 0;
    }

    get userTimezone() {
        return authState.userInfoObject?.timezone ?? '';
    }

    get variableTagsCount(): number {
        return clientInfoStore.storedClientInfo?.center_variable_tags_count ?? 0;
    }

    get isProvisionPhoneAvailable() {
        return (Boolean(clientInfoStore.storedClientInfo?.business_registration_identifier) && Boolean(clientInfoStore.storedClientInfo?.legal_business_name)) || (clientInfoStore.storedClientInfo?.address.country !== 'us');
    }

    get hasNylasEmailEnabled() {
        return featuresStore.isFeatureEnabled(FeatureConstants.NYLAS_EMAIL);
    }

    get hasNylasCalendarEnabled() {
        return featuresStore.isFeatureEnabled(FeatureConstants.NYLAS_CALENDAR);
    }

    private get showNylas(): boolean {
        return this.hasNylasEmailEnabled && this.hasNylasCalendarEnabled;
    }

    private get businessRegistrationLabel() {
        let businessRegistrationLabel = 'Business Registration Number';
        const country = clientInfoStore.storedClientInfo?.iso_country;
        switch (country) {
            case RegionsBusiness.US:
                businessRegistrationLabel = 'Employer Identification Number (EIN)';
                break;
            case RegionsBusiness.CA:
                businessRegistrationLabel = 'Business Number (BN)';
                break;
            case RegionsBusiness.AU:
                businessRegistrationLabel = 'Australian Business Number (ABN)';
                break;
            case RegionsBusiness.UK:
                businessRegistrationLabel = 'Unique Taxpayer Reference (UTR)';
                break;
            case RegionsBusiness.NZ:
                businessRegistrationLabel = 'New Zealand Business Number (NZBN)';
                break;
            case RegionsBusiness.IN:
                businessRegistrationLabel = 'Permanent Account Number (PAN)';
                break;
            default:
                businessRegistrationLabel = 'Business Registration Number';
        }
        return businessRegistrationLabel;
    }

    @Watch('modelValue')
    async activationChanged(flag: boolean) {
        // Array contains root parts that needed to be filtered out upon saving
        if (flag) {
            this.rootParts.push('http://', 'https://');
            this.rootParts.push(this.facebookRootConstant, this.youtubeRootConstant, this.pinterestRootConstant, this.twitterRootConstant, this.instagramRootConstant, this.linkedinRootConstant, this.yelpRootConstant);
            this.rootParts.push(this.facebookRootConstant.replaceAll('www.', ''), this.youtubeRootConstant.replaceAll('www.', ''),
                this.pinterestRootConstant.replaceAll('www.', ''), this.twitterRootConstant.replaceAll('www.', ''),
                this.instagramRootConstant.replaceAll('www.', ''), this.linkedinRootConstant.replaceAll('www.', ''),
                this.yelpRootConstant.replaceAll('www.', ''));
            if (this.location) {
                // For full center properties
                loadingState.loadingIncrement(this.loadingKey);
                this.loaded = false;
                const center = await centersRepo.getOne(this.location.id);
                this.centerDto.hours.thu_close = null;
                this.centerDto.hours.thu_open = null;
                this.centerDto = centerMapper.toUpdateDto(center);
                this.centerNylasCalendars = await nylasRepo.getCalendarsForCenter(this.location.id);
                // the manage hours component really expects the data to be a certain structure so instead of rewriting all of that I'm just converting the comms hours data to the same shape as hours of operations.
                this.communicationsHoursRemapped = reformatCommHours(cloneDeep(this.centerDto.communication_hours));
                await Promise.all([
                    this.initLogo(center),
                    this.initClassrooms(),
                    this.initCallSettings(center)
                ]);

                this.loaded = true;
                ++this.hoursKey;
                this.$nextTick(() => {
                    // Validate form when we pop up the modal.
                    this.form.validate();
                });

                loadingState.loadingDecrement(this.loadingKey);
            } else {
                this.centerDto = new CenterDto();
                if (!this.isLocationMergeFieldsFeatureEnabled) {
                    delete this.centerDto.variable_tags;
                }
                this.communicationsHoursRemapped = reformatCommHours(cloneDeep(this.centerDto.communication_hours));
                this.centerDto.social_media.yelp = '';
                this.socialMediaLinksPanelIndex = -1;
                this.variableTagsPanelIndex = -1;
                this.locationStaffPanelIndex = -1;
                this.hoursOfOperationPanelIndex = -1;
                this.automatedCommunicationHoursPanelIndex = -1;
                this.centerDto.timezone = this.userTimezone;
                this.centerDto.parent_organization_id = this.defaultOrgId;
                this.logoChange = false;
                this.logoExists = false;
                this.classrooms = [];
                this.classroomsRemoved = [];
                this.centerCallSettingsDto = null;
                this.logo = null;
                this.centerNylasCalendars = [];
                this.loaded = true;
                ++this.hoursKey;
            }
        } else {
            this.centerNylasCalendars = [];
            this.loaded = false;
        }
    }

    async created() {
        loadingState.loadingIncrement(this.loadingKey);
        await timezonesStore.init();
        const orgLevelsPromise = orgLevelsStore.init();
        const orgsPromise = orgsState.init();
        const brandsPromise = brandsState.init();
        const clientInfoPromise = clientInfoStore.init();
        const settingsPromise = settingsStore.init();
        if (this.isEnrollmentCenterEnabled) {
            const enrollmentSettingsPromise = enrollmentSettingsStore.init();
            await enrollmentSettingsPromise;
        }

        await orgLevelsPromise;
        await orgsPromise;
        await brandsPromise;
        await clientInfoPromise;
        await settingsPromise;

        this.setupReportingLevels();
        this.pipelineValueSettingsShow = settingsStore.stored.get(SettingNames.PIPELINE_VALUE)!.value as boolean;

        loadingState.loadingDecrement(this.loadingKey);
    }

    private async deleteLogo() {
        const result = await this.$swal({
            text: 'Are you sure you want to delete the logo for this center?',
            showConfirmButton: true,
            showCancelButton: true
        });

        if (result.isConfirmed) {
            this.logo = null;
        }
        this.logoChange = this.logoExists;
    }

    /**
     * Signal for editing added location
     */
    editLocation() {
        this.showSnack = false;
        this.$emit(EventTypes.ADDED, this.snackLocation);
    }

    /**
     * Initialize the call settings.
     */
    private async initCallSettings(center: Center): Promise<void> {
        if (center.contact.crm_phone.number_e164 !== null) {
            const settings = await centersRepo.getCenterCallSettings(center.id);
            this.centerCallSettingsDto = centerCallSettingsMapper.toUpdateDto(settings);
            this.showLocationCallSettingsButton = false;
        }
    }

    /**
     * Initialize the classrooms.
     */
    private async initClassrooms(): Promise<void> {
        // Get all the classrooms of the center to display on edit form and push them all in the classrooms array
        if (this.isClassroomsFeatureEnabled && this.location) {
            const classroomsResponse = await classesRepo.retrieveAll(this.location.id as number);
            classroomsResponse.forEach((classroom) => {
                this.classrooms.push(
                    {
                        original: classMapper.toUpdateDto(classroom),
                        current: classMapper.toUpdateDto(classroom)
                    });
            });
        }
    }

    /**
     * Initialize the logo.
     *
     * @param center
     */
    private async initLogo(center: Center): Promise<void> {
        if (this.location) {
            this.logo = center.logo ? await centersRepo.getLogo(this.location.id) as Logo : null;
            this.logoExists = !!this.logo;
        }
    }

    private logoUploaded(logo: Logo) {
        this.logo = logo;
        this.showUploadDialog = false;
        this.logoChange = true;
    }

    private setupReportingLevels() {
        this.reportingLevelInfo = [];

        const orgLevels: Array<OrganizationLevel> = orgLevelsStore.stored;
        const orgsArray: Array<Org> = orgsState.stored;
        orgLevels.forEach((element: OrganizationLevel) => {
            this.levelsMap.set(element.id, element.name);
        });
        orgsArray.forEach(element => {
            if (element.level !== LevelTypes.CENTER && this.levelsMap.get(element.level)) {
                this.reportingLevelInfo.push({
                    text: this.levelsMap.get(element.level) as string + ' : ' + element.name,
                    value: element.id
                });
            } else if (element.level === LevelTypes.CENTER &&
                this.defaultOrgId === 1 &&
                element.parent_organization &&
                element.parent_organization.id !== 1
            ) {
                this.defaultOrgId = element.parent_organization.id;
            }
        });
        this.reportingLevelInfo.sort((a, b) => {
            if (a.text.toLocaleLowerCase() < b.text.toLocaleLowerCase()) {
                return -1;
            } else {
                return 1;
            }
        });

        if (this.reportingLevelInfo.length > 0) {
            this.multipleReportingLevels = featuresStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE) && orgLevels.length > 2;
        }
    }

    private handleClose() {
        this.classrooms = [];
        this.classroomsRemoved = [];
        this.rootParts = [];
        this.socialMediaLinksPanelIndex = -1;
        this.variableTagsPanelIndex = -1;
        this.locationStaffPanelIndex = -1;
        this.hoursOfOperationPanelIndex = -1;
        this.automatedCommunicationHoursPanelIndex = -1;
        this.logoChange = false;
        this.showLocationCallSettingsButton = true;
        this.centerCallSettingsDto = null;
        this.communicationsHoursRemapped = null;
        this.form.reset();
        this.modelValue = false;
    }

    private addClass() {
        this.classrooms.push({
            original: new ClassCreatePostDto(),
            current: new ClassCreatePostDto()
        });
    }

    private async confirmNylasCalendarCancel() {
        if (this.location) {
            const result = await this.$swal({
                icon: 'warning',
                titleText: 'Are you sure?',
                text: ' If you cancel the current calendar integration, you can integrate with a different calendar or reintegrate with the current calendar. But you will need the account password for whatever calendar you choose to integrate with.',
                showConfirmButton: true,
                confirmButtonText: 'Proceed',
                showCancelButton: true,
                cancelButtonText: 'Go Back'
            });

            if (result.isConfirmed) {
                loadingState.loadingIncrement(this.loadingKey);

                await nylasRepo.revokeCalendarIntegrationByCenterId(this.location.id);
                this.centerNylasCalendars = await nylasRepo.getCalendarsForCenter(this.location.id);

                loadingState.loadingDecrement(this.loadingKey);
            }
        }
    }

    private async nylasSelectCalendar(data: any) {
        this.nylasCalendarOpen = false;
        loadingState.loadingIncrement(this.loadingKey);
        this.nylasAuth = data.data as NylasAuthCodeObject;
        this.nylasTokenObject = await nylasRepo.getTokenObject(this.nylasAuth.code, NylasScope.calendar);
        this.nylasCalendars = await nylasRepo.getCalendarsFromAccessToken(this.nylasTokenObject.grant_id, this.nylasTokenObject.access_token);
        loadingState.loadingDecrement(this.loadingKey);

        if (this.nylasCalendars.length > 0) {
            this.nylasSelectCalendarOpen = true;
        } else {
            // Some sort of error...
        }
    }

    private async nylasCalendarAddition() {
        this.nylasCalendarOpen = false;
        this.nylasSelectCalendarOpen = false;
        this.nylasCalendarAdditionOpen = true;
    }

    private nylasSelectCalendarClosed() {
        this.nylasSelectCalendarOpen = false;
    }

    private nylasCalendarAuthClosed() {
        this.nylasCalendarOpen = false;
    }

    private nylasCalendarAdditionClosed() {
        this.nylasCalendarAdditionOpen = false;
    }

    private async removeClass(classToRemove: ClassCreateDtoInterface | ClassUpdateDtoInterface) {
        if (this.location && this.location.id) {
            if (!classToRemove.id) {
                // Removing a row that was never saved
                this.classrooms = this.classrooms.filter(classroom => classroom.current !== classToRemove);
                return;
            }

            const result = await this.$swal({
                text: 'Are you sure you want to remove this class?',
                showConfirmButton: true,
                showCancelButton: true
            });

            if (result.isConfirmed) {
                // Push the removed classes in a separate array
                this.classroomsRemoved.push(classToRemove);
                this.classrooms = this.classrooms.filter(classroom => classroom.current !== classToRemove);
            }
        } else {
            this.classrooms = this.classrooms.filter(classroom => classroom.current !== classToRemove);
        }
    }

    private async nylasSaveCalendarIntegration(data: any) {
        loadingState.loadingIncrement(this.loadingKey);
        if (this.location?.id && this.nylasAuth && this.nylasTokenObject) {
            const calendar = this.nylasCalendars.find((calendar) => calendar.id === this.nylasCalendar);
            await nylasRepo.storeAuthCode(
                {
                    code: this.nylasAuth.code,
                    uuid: this.nylasAuth.state,
                    auth_token: this.nylasTokenObject,
                    center_id: this.location.id,
                    calendar_id: this.nylasCalendar,
                    calendar_name: calendar ? calendar.name : 'Default',
                    show_calendar: data as boolean
                });
                this.centerNylasCalendars = await nylasRepo.getCalendarsForCenter(this.location.id);
        } else {
            loadingState.loadingDecrement(this.loadingKey);
            this.nylasCalendarAdditionClosed();
            throw Error('Auth Object(s) Invalid.');
        }

        this.nylasCalendarAdditionClosed();
        loadingState.loadingDecrement(this.loadingKey);
    }

    /**
     * Set the id of the calendar we want to use.
     *
     * @param calendarId
     * @private
     */
    private setCalendar(calendarId: string | null) {
        this.nylasCalendar = calendarId;
        this.nylasSelectCalendarClosed();
        this.nylasCalendarAddition();
    }

    /**
     * Set the call settings dto from the payload from the modal.
     *
     * @param payload
     */
    private setCallSettingsFromModal(payload: CenterSettingsModalPayload) {
        if (this.location) {
            this.location.contact.crm_phone.number_e164 = payload.crm_phone_number;
        }
        this.centerCallSettingsDto = payload.dto;
        this.showLocationCallSettingsButton = false;
        if (this.location) {
            this.$emit(EventTypes.UPDATED, this.location);
        }
    }

    private async submit() {
        this.rootParts.forEach(root => {
            if (this.centerDto.social_media.facebook.indexOf(root) !== -1) {
                this.centerDto.social_media.facebook = this.centerDto.social_media.facebook.replaceAll(root, '');
            }

            if (this.centerDto.social_media.youtube.indexOf(root) !== -1) {
                this.centerDto.social_media.youtube = this.centerDto.social_media.youtube.replaceAll(root, '');
            }

            if (this.centerDto.social_media.linkedin.indexOf(root) !== -1) {
                this.centerDto.social_media.linkedin = this.centerDto.social_media.linkedin.replaceAll(root, '');
            }

            if (this.centerDto.social_media.instagram.indexOf(root) !== -1) {
                this.centerDto.social_media.instagram = this.centerDto.social_media.instagram.replaceAll(root, '');
            }

            if (this.centerDto.social_media.twitter.indexOf(root) !== -1) {
                this.centerDto.social_media.twitter = this.centerDto.social_media.twitter.replaceAll(root, '');
            }

            if (this.centerDto.social_media.pinterest.indexOf(root) !== -1) {
                this.centerDto.social_media.pinterest = this.centerDto.social_media.pinterest.replaceAll(root, '');
            }

            if (this.centerDto.social_media.yelp!.indexOf(root) !== -1) {
                this.centerDto.social_media.yelp! = this.centerDto.social_media.yelp!.replaceAll(root, '');
            }
        });

        nullifyBlankHours(this.centerDto.hours);

        if (this.communicationsHoursRemapped) {
            this.centerDto.communication_hours = reformatCenterHours(this.communicationsHoursRemapped);
        }

        this.centerDto.open_saturday = Boolean(this.centerDto.hours.sat_open);
        this.centerDto.open_sunday = Boolean(this.centerDto.hours.sun_open);

        Object.keys(this.centerDto.staff).forEach((key) => {
            const typedKey = key as keyof CenterStaffDtoInterface; // Explicitly narrow the type
            if (this.centerDto.staff[typedKey] === undefined) {
                this.centerDto.staff[typedKey] = null;
            }
        });

        centerMapper.cleanVariableTags(this.centerDto);
        if (this.location === null) {
            const confirm = await this.$swal({
                icon: 'warning',
                text: 'Your subscription billing will be updated effective immediately to include the new location at your prevailing rate.',
                showConfirmButton: true,
                showCancelButton: true
            });
            if (confirm.isConfirmed) {
                loadingState.loadingIncrement(this.loadingKey);
                const newCenter: Center = await centersRepo.createCenter(this.centerDto);
                if (this.centerCallSettingsDto) {
                    const systemNumber = await centersRepo.provisionCenterNumber(newCenter.id);
                    newCenter.contact.crm_phone.number_e164 = systemNumber.crm_phone_number;
                    await centersRepo.setCenterCallSettings(newCenter.id, this.centerCallSettingsDto);
                }
                if (this.logo) {
                    // Send PUT to marketing/brands/id/logo
                    await centersRepo.uploadLogo(newCenter.id, logoMapper.toCreateDto(this.logo));
                }
                this.newCenterId = newCenter.id;
                for (const classroom of this.classrooms) {
                    const classClone = cloneDeep(classroom.current);
                    await classesRepo.create(this.newCenterId, classClone as ClassCreatePostDto);
                }
                loadingState.loadingDecrement(this.loadingKey);
                this.modelValue = false;
                this.$emit(EventTypes.UPDATED, newCenter);
                this.snackText = 'Location Added';
                this.snackLocation = newCenter;
                this.showSnack = true;
            }
        } else {
            loadingState.loadingIncrement(this.loadingKey);
            if (this.location.avg_weekly_tuition !== this.centerDto.avg_weekly_tuition) {
                statsStore.resetEstimatedRevenue();
            }
            if (this.logoExists && (this.logo === null)) {
                await centersRepo.deleteLogo(this.location.id);
            } else if (this.logo) {
                await centersRepo.uploadLogo(this.location.id, logoMapper.toCreateDto(this.logo));
            }
            for (const classroom of this.classrooms) {
                if (!isEqual(classroom.original, classroom.current)) {
                    const classClone = cloneDeep(classroom.current);
                    // If the class existed by checking the id, update it otherwise create a new class
                    if (classClone.id) {
                        await classesRepo.update(this.location.id, classClone as ClassUpdateDtoInterface);
                    } else {
                        await classesRepo.create(this.location.id, classClone as ClassCreatePostDto);
                    }
                }
            }
            // Completely delete the element from the removed array by calling the delete endpoint
            while (this.classroomsRemoved.length > 0) {
                await classesRepo.delete(this.location.id, this.classroomsRemoved.pop()?.id!);
            }
            const updatedCenter: Center = await centersRepo.updateCenter(this.location.id, this.centerDto);
            if (this.centerCallSettingsDto) {
                await centersRepo.setCenterCallSettings(this.location.id, this.centerCallSettingsDto);
            }
            updatedCenter.id = this.location.id;
            loadingState.loadingDecrement(this.loadingKey);
            this.modelValue = false;
            this.$emit(EventTypes.UPDATED, updatedCenter);
            this.snackText = 'Location Saved';
            this.snackLocation = null;
            this.showSnack = true;
        }
        this.handleClose();
    }
}
